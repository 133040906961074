import _ from "lodash";

export default (filters, columns, data) => {
  let filteredCols = [...columns];
  filters.forEach(d => {
    filteredCols = filteredCols.map(a => {
      if (a.dataIndex == d.dataIndex) {
        const filtereds = _.uniqBy(data, e => e[d.dataIndex]).map(k => {
          return {
            text: k[d.dataIndex],
            value: k[d.dataIndex],
          };
        });

        return {
          ...a,
          filters: filtereds,
        };
      } else {
        return a;
      }
    });
  });

  return filteredCols;
};
