import React from "react";
import "antd/dist/antd.css";
import "./App.css";
import Main from "./Pages/Main";
import { Route, Switch } from "react-router-dom";

function App() {
  return (
    <Switch>
      <Route path="/">
        <Main></Main>
      </Route>
    </Switch>
  );
}

export default App;
