import React from "react";
import MainTable from "../../Tables/MainTable";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setCurrentPage } from "../../../Actions/AppActions";
import GPS from "../../../Pages/GPS";

const SimplePage = ({ setCurrentPage, searchDataSource }) => {
  const changePage = () => {
    setCurrentPage(GPS);
  };
  return (
    <>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          fontSize: "18px",
          justifyContent: "space-between",
          fontWeight: "600",
        }}
      >
        Агентлар рўйхати
        <div>
          <Link to="/agent-locations" onClick={changePage}>
            Agent GPS
          </Link>
        </div>
      </div>
      <MainTable costumColumns={false} data={searchDataSource}></MainTable>
    </>
  );
};

export default connect(null, { setCurrentPage })(SimplePage);
