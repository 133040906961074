import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MapContainer,
  Marker,
  Polyline,
  Popup,
  TileLayer,
} from "react-leaflet";
import L from "leaflet";
import { DatePicker, Select, Space, Table, Checkbox  } from "antd";
import fetchApi from "../../../Functions/fetchApi";
import moment from "moment";
import { RiBattery2ChargeFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import MainTable from "../../Tables/MainTable";
import PhotoPng from "../../../images/camera.png";
import MarkerStick from "../../../images/marker.png";
import MijozStick from "../../../images/pont.png";
import MoneyMPng from "../../../images/money_marker.png";
import PhotoMPng from "../../../images/marker_red.png";
import UserPng from "../../../images/map-user.svg";

import UserLocM from "../../../images/user-loc-marker.png"; 

const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;
const plainOptions = ['Klentlar', 'Buyurtma', 'To`lov','Foto hisobot', 'Yo`nalish']; 

const columns = [
  {
    title: <div className="table_head">Vaqt</div>,
    dataIndex: "time_update",
    key: "fio",
    render: text => moment(text).format("HH:mm"),
  },
  {
    title: <div className="table_head">Turi</div>,
    dataIndex: "zakaz_raqam",
    key: "zakaz_raqam",
    render: text => {
      if (text == -2) {
        return <img src={UserPng}></img>;
      } else if (text == -5) {
        return <img src={PhotoMPng}></img>;
      } else if (text == -1) {
        return <img src={MoneyMPng}></img>;
      } else if (text == 0) {
        return "";
      } else if (text < 0) {
        return "";
      } else {
        return <div><img src={MarkerStick}></img>{text}</div>;
      }
    },
  },
  {
    title: <div className="table_head">Aniqlik</div>,
    align: "center",
    dataIndex: "farqi",
    render: text => Math.round(text) + "m",
  },
  {
    title: <div className="table_head">Batareya</div>,
    dataIndex: "battery",
    render: text => Math.round(text),
    key: "time_update",
  },
];

const GPSPage = () => {
  const [agents, setAgents] = useState([]);
  const [locations, setLocations] = useState([]);
  const [Mijozlocations, setMijozLocations] = useState([]);
  const [clickedMarker, setClickedMarker] = useState(null);
  const [checkedList, setCheckedList] = useState(plainOptions);

  const [values, setValues] = useState({
    agent_id: null,
    dates: [moment(moment().format("YYYY-MM-DD 08:00:00")),moment(moment().format("YYYY-MM-DD 20:00:00"))],
    dateStrings: [moment().format("YYYY-MM-DD 08:00:00"),moment().format("YYYY-MM-DD 20:00:00")],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAgents();
  }, []);
  const getAgents = async () => {
    setLoading(true);
    const res = await fetchApi("get_agentlar.php", "get");
    if (res.status === 200) {
      setAgents(res.data);
    }

    setLoading(false);
  };

  const handleChange = value => {
    
    console.log(checkedList);
    setValues({
      ...values,
      agent_id: value,
      checkbox: checkedList
    });
  };
  const handleDates = (dates, dateStrings) => {
    setValues({
      ...values,
      checkbox: checkedList,
      dates,
      dateStrings,
    });
  };

  useEffect(() => {
    if (values.agent_id && values.dates.length == 2) {
      getAgentLocations(values.agent_id, values.dateStrings);
    }
  }, [values.agent_id, values.dates, checkedList]);

  const getAgentLocations = async (agent_id, dateStrings) => {
    setLoading(true);
    const res = await fetchApi("get_agent_locations.php", "POST", {
      agent_id,
      checkbox: checkedList,
      date1: dateStrings[0],
      date2: dateStrings[1],
    });

    if (res.status === 200) {
      setLocations(res.data[0].location);
      setMijozLocations(res.data[0].mijoz);
    }

    setLoading(false);
  };

  const polyline = useMemo(() => {
    return locations.map(loc => [loc.lat, loc.lng]);
  }, [locations]);

  const simpleMarker = new L.Icon({
    iconUrl: MarkerStick,
    iconRetinaUrl: MarkerStick,
    popupAnchor: [-0, -0],
    iconSize: [24, 32],
  });

  const MijozMarker = new L.Icon({
    iconUrl: MijozStick,
    iconRetinaUrl: MijozStick,
    popupAnchor: [-0, -0],
    iconSize: [20, 20],
  });
  const photoMarker = new L.Icon({
    iconUrl: PhotoMPng,
    iconRetinaUrl: PhotoMPng,
    popupAnchor: [-0, -0],
    iconSize: [21, 32],
  });
  const moneyMarker = new L.Icon({
    iconUrl: MoneyMPng,
    iconRetinaUrl: MoneyMPng,
    popupAnchor: [-0, -0],
    iconSize: [32, 32],
  });
  const userLocMarker = new L.Icon({
    iconUrl: UserLocM,
    iconRetinaUrl: UserLocM,
    popupAnchor: [-0, -0],
    iconSize: [32, 32],
  });

  const clickRef = useRef(null);
  
  const onChange = (list) => {
    setCheckedList(list);
  };

  useEffect(() => {
    if (clickRef) {
      const marker = clickRef.current;
      if (marker) {
        console.log(marker);
        marker.openPopup();
      }
    }
  }, [clickedMarker]);

  return (
    <>
      <Link to="/">Ortga</Link>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "3fr 1fr",
          gap: "10px",
          width: "100%",
          height: "87vh",
          overflow: "auto",
        }}
      >
        <div style={{ height: "85vh" }}>
          <MapContainer center={[40.43004, 71.7674]} zoom={13}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {locations.length > 0 ? (
              <>
                {locations.map(loc =>
                  loc.zakaz_raqam == 0 || loc.zakaz_raqam == -2 ? null : (
                    <>
                      <Marker
                        icon={
                          loc.zakaz_raqam == -5 ? photoMarker : (loc.zakaz_raqam == -1 ? moneyMarker : simpleMarker)
                        }
                        position={[loc.lat, loc.lng]}
                      >
                        <Popup>
                          {
                            agents.find(agent => agent.Id == values.agent_id)
                              ?.fio
                          }{" "}
                          нинг<br></br>{" "}
                          {moment(loc.time_update).format("DD.MM.YYYY HH:mm")}
                          <br></br>
                          вақтдаги жойлашуви <br></br>
                          <RiBattery2ChargeFill
                            style={{
                              color: "var(--main-color)",
                              verticalAlign: "middle",
                            }}
                          ></RiBattery2ChargeFill>
                          {loc.battery}% заряди бор эди.<br></br>
                          {loc.izox}  
                        </Popup>
                      </Marker>
                    </>
                  )
                )}
                {Mijozlocations.map(loc =>
                  (
                    <>
                      <Marker
                        icon={MijozMarker}
                        position={[loc.lat, loc.lng]}
                      >
                        <Popup>
                          {loc.izox}  
                        </Popup>
                      </Marker>
                    </>
                  )
                )}
                {polyline.length > 0 ? <Polyline
                  className="stroke-polyline"
                  pathOptions={{
                    lineJoin: "round",
                    lineCap: "round",
                  }}
                  positions={polyline}
                />:null}
              </>
            ) : null}

            {clickedMarker ? (clickedMarker.battery ? (
              <Marker
                icon={userLocMarker}
                ref={clickRef}
                position={[clickedMarker.lat, clickedMarker.lng]}
              >
                <Popup>
                  {agents.find(agent => agent.Id == values.agent_id)?.fio} нинг
                  <br></br>{" "}
                  {moment(clickedMarker.time_update).format("DD.MM.YYYY HH:mm")}
                  <br></br>
                  вақтдаги жойлашуви <br></br>
                  <RiBattery2ChargeFill
                    style={{
                      color: "var(--main-color)",
                      verticalAlign: "middle",
                    }}
                  ></RiBattery2ChargeFill>
                  {clickedMarker.battery}% заряди бор эди.<br></br>{clickedMarker.izox}
                </Popup>
              </Marker> 
            ) : (
              <Marker
                icon={userLocMarker}
                ref={clickRef}
                position={[clickedMarker.lat, clickedMarker.lng]}
              >
                <Popup>
                      {clickedMarker.izox}
                </Popup>
              </Marker>
            )): null}
          </MapContainer>
        </div>

        <div>
          <Space direction="vertical" size={"middle"}>
            <RangePicker
              value={values.dates}
              onChange={handleDates}
              showTime
            ></RangePicker>
            <Select
              style={{
                width: 120,
              }}
              value={values.agent_id}
              onChange={handleChange}
              placeholder="Agent tanlang"
              loading={loading}
              options={agents.map(agent => ({
                value: agent.Id,
                label: agent.fio,
              }))}
            />
            <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />

            <MainTable
              setClickedMarker={setClickedMarker}
              scroll={{ y: "70vh" }}
              data={locations}
              costumColumns={columns}
            />
          </Space>
        </div>
      </div>
    </>
  );
};

export default GPSPage;
