export const SET_MAIN_TABLE_DATA = "SET_MAIN_TABLE_DATA";
export const SET_VALUES = "SET_VALUES";
export const SET_ROW_ID = "SET_ROW_ID";
export const SET_LOADING = "SET_LOADING";
export const SET_MODAL_DATA = "SET_MODAL_DATA";
export const GET_ALL_DATA = "GET_ALL_DATA";
export const GET_LOGIN = "GET_LOGIN";
export const OPEN_SIDE = "OPEN_SIDE";
export const DOCUMENT_CHANGE = "DOCUMENT_CHANGE";
export const SET_MODAL = "SET_MODAL";
export const SET_MODAL2 = "SET_MODAL2";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

export const DELETE_LINKS = "DELETE_LINKS";
export const SET_FILE = "SET_FILE";
export const SET_INNER_MODAL = "SET_INNER_MODAL";
export const ADD_DOCUMENT = "ADD_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const CHANGE_DOCUMENT_OBJ = "CHANGE_DOCUMENT_OBJ";
export const STATE_CHANGE_DETECT = "STATE_CHANGE_DETECT";

//ostatka
export const GET_INNER_DATA = "GET_INNER_DATA";
export const CHANGE_ALL_DOCUMENT = "CHANGE_ALL_DOCUMENT";
export const SET_SECONDARY_MODAL = "SET_SECONDARY_MODAL";
export const GET_HISOBOT = "GET_HISOBOT";
