// import { Table } from "ant-table-extensions";
import { Table } from "antd";
import { connect } from "react-redux";
import {
  changeDocumentObj,
  setInnerModal,
  setRowId,
  setValues,
  setModal,
  stateDetector,
} from "../../Actions/AppActions";
import "./Table.css";
import FilterColumns from "../../Functions/FilterColumns";
import { useHistory } from "react-router";
const MainTable = ({
  columns,
  costumColumns,
  data,
  setRowId,
  rowId,
  loading,
  summary,
  innerTable,
  setClickedMarker,
  currentPage,
  modal,
  scroll,
}) => {
  const onClickRow = record => {
    return {
      onClick: () => {
        if (!innerTable) {
          setRowId(record.Id);
        }

        if (setClickedMarker) {
          setClickedMarker(record);
        }
      },
    };
  };
  const setRowClassName = (record, index) => {
    let className = "";
    if (record.Id == rowId) {
      className = className + "clickRowStyl";
    }

    return className;
  };

  const tableFooter = () => {
    if ((currentPage.modalJami && modal && !innerTable) || summary) {
      return (
        <Table.Summary fixed="bottom">
          <Table.Summary.Row>
            <Table.Summary.Cell>Жами:</Table.Summary.Cell>
          </Table.Summary.Row>
        </Table.Summary>
      );
    } else return null;
  };
  let filteredColumns = [];
  if (currentPage.filters) {
    filteredColumns = FilterColumns(currentPage.filters, columns, data);
  } else {
    filteredColumns = columns;
  }
  return (
    <>
      <div className={"main_table"}>
        <Table
          size="small"
          pagination={false}
          bordered
          rowClassName="table_row"
          columns={costumColumns ? costumColumns : filteredColumns}
          dataSource={data}
          loading={loading}
          scroll={scroll || null}
          onRow={onClickRow}
          rowSelection={null}
          rowClassName={setRowClassName}
          summary={tableFooter}
        />
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    rowId: state.app.rowId,
    values: state.app.values,
    allData: state.app.allData,
    loading: state.app.loading,
    columns: state.app.currentPage.columns,
    currentPage: state.app.currentPage,
    modal: state.app.modal,
    mainTableData: state.app.mainTableData,
  };
};
export default connect(mapStateToProps, {
  setRowId,
  changeDocumentObj,
  setInnerModal,
  setValues,
  setModal,
  stateDetector,
})(MainTable);
