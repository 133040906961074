import React, { useState } from "react";
import { Layout, Menu } from "antd";
import "./Sidebar.css";

import "../Header/Header.css";
import MainModal from "../Modals/MainModal";
import { useHistory, useLocation } from "react-router-dom";

import Page from "./Page";
import {
  deleteLinks,
  getAllData,
  getHisobot,
  getMainTableData,
  setCurrentPage,
  setRowId,
} from "../../Actions/AppActions";
import { connect } from "react-redux";
import { useEffect } from "react";
import Modal2 from "../../Pages/Components/Modal";
import PagesClass from "../../Pages/PagesClass";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
const { Content, Sider } = Layout;
const { SubMenu } = Menu;

function SiderDemo({
  setCurrentPage,
  currentPage,
  data,
  getMainTableData,
  getAllData,
  allData,
  getHisobot,
}) {
  const [searchDataSource, setSearchDataSource] = useState([]);
  const [collapsed, setCollapsed] = useState(
    useMediaQuery({ query: "(max-width: 500px)" })
  );
  const big = useMediaQuery({ query: "(max-width: 500px)" });
  useEffect(() => {
    setCollapsed(big);
  }, [big]);
  const scrollContainer = document.querySelector(".link_bar");
  useEffect(() => {
    setTimeout(() => {
      const off = document.querySelector(".active_link")?.offsetLeft;
      const ff = off - 216;
      scrollContainer?.scroll({
        top: 0,
        left: ff,
        behavior: "smooth",
      });
    }, 100);
    if (
      currentPage.path &&
      currentPage.path != "/service" &&
      currentPage.path != "/hisobot"
    ) {
      if (currentPage.allData) {
        getAllData(currentPage.allData.map(al => al.prop));
      }
      if (currentPage.path.includes("hisobot")) {
        getHisobot(currentPage.path, {
          sana: moment().format("YYYY-MM-DD"),
          sana2: moment().format("YYYY-MM-DD"),
        });
      } else {
        getMainTableData(currentPage.path);
        // setInterval(() => {
        //   getMainTableData(currentPage.path);
        // }, 5000);
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage.allData?.length > 0) {
      setSearchDataSource(
        data.map(d => {
          let a = {};
          currentPage.allData.forEach(obj => {
            if (allData[obj.prop]?.find(k => k.Id == d[obj.value])) {
              a[obj.prop] = allData[obj.prop]?.find(k => k.Id == d[obj.value])[
                obj.get
              ];
            }
          });

          return { ...d, ...a };
        })
      );
    } else {
      setSearchDataSource(data);
    }
  }, [data, allData]);
  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };

  const diffLocation = useLocation();

  scrollContainer?.addEventListener("wheel", evt => {
    evt.preventDefault();
    scrollContainer.scrollLeft += evt.deltaY;
  });

  useEffect(() => {
    let pagess = [];
    PagesClass.forEach(d => {
      if (d.submenu) {
        pagess = [...pagess, ...d.submenu];
      } else {
        pagess = [...pagess, d];
      }
    });
    const pages = [...pagess];
    setCurrentPage(pages.find(p => p.path == diffLocation.pathname));
  }, [diffLocation.pathname]);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout className="site-layout">
        <Content style={{ margin: "15px" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, margin: 0, minHeight: "calc(100vh - 30px" }}
          >
            <Page type={currentPage.type} dataSource={searchDataSource}></Page>
          </div>
          <MainModal></MainModal>
          <Modal2></Modal2>
        </Content>
      </Layout>
    </Layout>
  );
}
const mapStateToProps = state => {
  return {
    currentPage: state.app.currentPage,
    links: state.app.links,
    data: state.app.mainTableData,
    loading: state.app.loading,
    allData: state.app.allData,
  };
};
export default connect(mapStateToProps, {
  setCurrentPage,
  setRowId,
  deleteLinks,
  getMainTableData,
  getHisobot,
  getAllData,
})(SiderDemo);
