import React from "react";
import { BaseUrl } from "../../../BaseUrl";
import "./Upload.css";
import { RiCheckboxCircleFill, RiFileAddLine } from "react-icons/ri";
import Loading from "./Loading";
import { useState } from "react";
import { connect } from "react-redux";

function FileUpload({ onImage, values, currentPage }) {
  const [loading, setLoading] = useState(false);

  const onChange = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("avatar", file);
      const res = await fetch(`${BaseUrl}${currentPage.path}file`, {
        method: "POST",
        body: formData,
      });
      const data = await res.text();
      onImage({ target: { name: "link_pdf", value: data } });
    }
    setLoading(false);
  };

  return (
    <>
      <label for="file-upload" class="custom-file-upload">
        <div style={{ gridGap: "10px", display: "flex", alignItems: "center" }}>
          <RiFileAddLine></RiFileAddLine>
          {values.link_pdf ? values.link_pdf : "Файл юклаш"}
        </div>

        {loading ? (
          <Loading></Loading>
        ) : (
          values.link_pdf && (
            <RiCheckboxCircleFill
              style={{ color: "green", fontSize: "16px" }}
            ></RiCheckboxCircleFill>
          )
        )}
      </label>
      <input onChange={onChange} id="file-upload" type="file" />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    values: state.app.values,
    currentPage: state.app.currentPage,
  };
};
export default connect(mapStateToProps, {})(FileUpload);
