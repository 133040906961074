import React from "react";
import ModalForm from "./ModalForm";
import { connect } from "react-redux";
import { setSecondaryModal, setValues2 } from "../../Actions/AppActions";
import "./Modal.css";
import Modal from "antd/lib/modal/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
function SecondaryModal({ setSecondaryModal, secondaryModal, setValues2 }) {
  const clearModal = () => {
    setSecondaryModal({
      title: "",
      open: false,
      path: "",
      forms: [],
    });
    setValues2({});
  };
  return (
    <Modal
      centered
      maskClosable={false}
      keyboard={false}
      title={
        <div className="modal-title">
          <span>{secondaryModal.title}</span>
        </div>
      }
      width="900px"
      onCancel={clearModal}
      visible={secondaryModal.open}
      footer={null}
      closeIcon={<RiCloseCircleFill />}
    >
      <ModalForm clearModal={clearModal}></ModalForm>
    </Modal>
  );
}
const mapStateToProps = state => {
  return {
    secondaryModal: state.app.secondaryModal,
  };
};
export default connect(mapStateToProps, {
  setSecondaryModal,
  setValues2,
})(SecondaryModal);
