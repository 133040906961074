import moment from "moment";
import { RiUserStarFill } from "react-icons/ri";
import Moment from "react-moment";
import { SIMPLE } from "../Component/Sidebar/PageTypes";
import SanaFormat from "../Functions/SanaFormat";
import MapTrigger from "./Components/MapTrigger";
import "moment/locale/ru";

const AsosiyOyna = {
  title: "Nur Biznes Distribution",
  path: "/",
  icon: <RiUserStarFill></RiUserStarFill>,
  type: SIMPLE,
  columns: [
    {
      title: <div className="table_head">№</div>,
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: <div className="table_head">Ф.И.Ш</div>,
      dataIndex: "fio",
      key: "fio",
      width: "215px",
    },
    {
      title: <div className="table_head">Телефон рақам</div>,
      dataIndex: "telefon",
      key: "telefon",
      width: "145px",
    },
    {
      title: <div className="table_head">Локация</div>,
      align: "center",
      render: (text, record) => <MapTrigger record={record}></MapTrigger>,
      width: "145px",
    },
    {
      title: <div className="table_head">Ўзгарган вақти</div>,
      dataIndex: "time_update",
      key: "time_update",
      width: "145px",
      render: (text, record) => <div>{SanaFormat(text)}</div>,
    },
    {
      title: <div className="table_head">Фарқи</div>,
      dataIndex: "time_update",
      key: "time_update",
      width: "145px",
      render: (text, record) => {
        if (moment().diff(moment(text)) > 1800000) {
          return (
            <div style={{ color: "red", fontWeight: 600 }}>
              <Moment locale="ru" fromNow>
                {text}
              </Moment>
            </div>
          );
        } else {
          return (
            <div style={{ fontWeight: 600 }}>
              <Moment locale="ru" fromNow>
                {text}
              </Moment>
            </div>
          );
        }
      },
    },
    {
      title: <div className="table_head">Батарея</div>,
      dataIndex: "battery",
      key: "fio",
      width: "50px",
      render: text => (text ? text + "%" : null),
    },
  ],
  forms: [],
};
export default AsosiyOyna;
