import {
  SET_LOADING,
  SET_MAIN_TABLE_DATA,
  SET_ROW_ID,
  SET_VALUES,
  GET_ALL_DATA,
  DOCUMENT_CHANGE,
  SET_MODAL,
  SET_CURRENT_PAGE,
  DELETE_LINKS,
  SET_FILE,
  SET_INNER_MODAL,
  ADD_DOCUMENT,
  DELETE_DOCUMENT,
  GET_INNER_DATA,
  CHANGE_ALL_DOCUMENT,
  SET_MODAL2,
  SET_SECONDARY_MODAL,
  GET_HISOBOT,
  CHANGE_DOCUMENT_OBJ,
  STATE_CHANGE_DETECT,
} from "../Actions/AppTypes";

const initialState = {
  auth: false,
  mainTableData: [],
  currentPage: {},
  links: [],
  modal: false,
  modal2: false,
  secondaryModal: {
    open: false,
    title: "",
    path: "",
    forms: [],
  },
  values: {},
  values2: {},
  hisobot: null,
  innerModal: false,
  rowId: null,
  file: "",
  loading: false,
  rowId: null,
  allData: {},
  innerData: {},
  stateDetector: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MAIN_TABLE_DATA:
      return {
        ...state,
        mainTableData: action.payload,
      };
    case GET_ALL_DATA:
      return {
        ...state,
        allData: action.payload,
      };
    case GET_HISOBOT:
      return {
        ...state,
        hisobot: action.payload,
      };
    case GET_INNER_DATA:
      return {
        ...state,
        innerData: action.payload,
      };
    case SET_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    case STATE_CHANGE_DETECT:
      return {
        ...state,
        stateDetector: !state.stateDetector,
      };
    case SET_MODAL2:
      return {
        ...state,
        modal2: action.payload,
      };

    case SET_SECONDARY_MODAL:
      return {
        ...state,
        secondaryModal: action.payload,
      };

    case SET_INNER_MODAL:
      return {
        ...state,
        innerModal: action.payload,
      };

    case CHANGE_ALL_DOCUMENT:
      return {
        ...state,
        values: {
          ...state.values,
          document: action.payload,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ADD_DOCUMENT:
      const data = state.values.document ? state.values.document : [];
      if (!data.find(d => d.Id == action.payload.Id)) {
        data.push(action.payload);
      }

      return {
        ...state,
        values: {
          ...state.values,
          document: data,
        },
      };
    case DELETE_DOCUMENT:
      const filteredData = [...state.values.document].filter(
        d => d.Id != action.payload
      );

      return {
        ...state,
        values: {
          ...state.values,
          document: filteredData,
        },
      };

    case DOCUMENT_CHANGE:
      const actualData = [...state.values.document];
      const mapedData = actualData.map(obj => {
        if (obj.Id == state.rowId) {
          return {
            ...obj,
            [action.payload.target.name]: action.payload.target.value,
          };
        } else return obj;
      });
      return {
        ...state,
        values: {
          ...state.values,
          document: mapedData,
        },
      };

    case CHANGE_DOCUMENT_OBJ:
      const currentData = [...state.values.document];
      const curMapedData = currentData.map(obj => {
        if (obj.Id == action.payload.rowId) {
          return action.payload.data;
        } else return obj;
      });
      return {
        ...state,
        values: {
          ...state.values,
          document: curMapedData,
        },
      };
    case SET_FILE:
      return {
        ...state,
        file: action.payload,
      };
    case DELETE_LINKS:
      return {
        ...state,
        links: state.links.filter(d => d.path !== action.payload.path),
      };

    case SET_CURRENT_PAGE:
      let a = [];
      if (state.links.find(d => d.path == action.payload.path)) {
        a = state.links.filter(d => d.path !== action.payload.path);
        a.push(action.payload);
      } else {
        a = [...state.links];
        a.push(action.payload);
      }
      return {
        ...state,
        currentPage: action.payload,
        links: a,
      };

    case SET_ROW_ID:
      return {
        ...state,
        rowId: action.payload,
      };
    case SET_VALUES:
      return {
        ...state,
        values: action.payload,
      };

    case "SET_VALUES_2":
      return {
        ...state,
        values2: action.payload,
      };
    default:
      return state;
  }
};
