import React from "react";
import { RiMapPin2Fill } from "react-icons/ri";
import { connect } from "react-redux";
import { setModal2, setValues } from "../../Actions/AppActions";

const MapTrigger = ({ setModal2, record, setValues }) => {
  return (
    <RiMapPin2Fill
      onClick={() => {
        setValues(record);
        setModal2(true);
      }}
      style={{ color: "var(--main-color)", fontSize: "18px" }}
    ></RiMapPin2Fill>
  );
};

export default connect(null, { setModal2, setValues })(MapTrigger);
