// import { Table } from "ant-table-extensions";
import { Table } from "antd";
import { connect } from "react-redux";
import {
  changeDocumentObj,
  setInnerModal,
  setRowId,
  setValues,
  setModal,
  stateDetector,
} from "../../Actions/AppActions";
import "./Table.css";
import Text from "antd/lib/typography/Text";
import FilterColumns from "../../Functions/FilterColumns";
import NumberFormat from "../../Functions/NumberFormat";
import { useHistory } from "react-router";
const SecondTable = ({
  columns,
  costumColumns,
  data,
  setRowId,
  rowId,
  notX,
  loading,
  summary,
  changeDocumentObj,
  innerTable,
  setInnerModal,
  notPag,
  currentPage,
  stateDetector,
  modal,
  setValues,
  setModal,
  mainTableData,
  allData,
}) => {
  const { location } = useHistory();
  const onClickRow = (record) => {
    return {
      onClick: () => {
        if (!innerTable) {
          setRowId(record.Id);
        }
      },
      onDoubleClick: () => {
        if (innerTable) {
          let obj = {};
          if (currentPage.forms?.find((f) => f.type == "document")) {
            const uniq = currentPage.forms?.find((f) => f.type == "document")
              .inputs[0].innerModal?.unique;
            if (uniq) {
              obj = { ...record };
            } else {
              if (
                currentPage.path == "/maxsulot-kirimi" ||
                currentPage.path == "/yuk-beruvchi-vozvrat"
              ) {
                var obj1 = {
                  kirim_som: record.valyuta_turi == 2 ? record.kirim_narxi : 0,
                  kirim_val: record.valyuta_turi == 1 ? record.kirim_narxi : 0,
                  sotish_som:
                    record.valyuta_turi == 2 ? record.sotish_narxi : 0,
                  sotish_val:
                    record.valyuta_turi == 1 ? record.sotish_narxi : 0,
                  zapis: false,
                };
              }
              obj = {
                ...record,
                ...obj1,
                element_id: record.Id,
              };
            }
          }
          changeDocumentObj(obj, rowId);
          setInnerModal(false);
          stateDetector();
        } else {
          if (!modal) {
            setRowId(record.Id);
            let obj = mainTableData.find((d) => d.Id == record.Id);
            if (
              location.pathname == "/servis/brendlar" &&
              currentPage.defaultDocument
            ) {
              obj = {
                ...obj,
                document: allData[currentPage.defaultDocument.allData]
                  .filter((d) => d[currentPage.defaultDocument.prop] == rowId)
                  .map((m) => {
                    return { ...m, element_id: m.Id };
                  }),
              };
            }
            setValues(obj);
            setModal(true);
          }
        }
      },
    };
  };
  const setRowClassName = (record, index) => {
    let className = "";
    if (record.Id == rowId) {
      className = className + "clickRowStyl";
    }

    if (data.length - 1 == index) {
      className = className + " scroll-row";
    }

    return className;
  };

  const tableFooter = () => {
    if ((currentPage.modalJami && modal && !innerTable) || summary) {
      return (
        <Table.Summary fixed="bottom">
          <Table.Summary.Row>
            <Table.Summary.Cell>Жами:</Table.Summary.Cell>
            {/* {JamiClass(currentPage.modalJami, data).map(d => (
              <Table.Summary.Cell style={{ textAlign: "right" }}>
                <Text>{d ? NumberFormat(d.toFixed(2)) : ""}</Text>
              </Table.Summary.Cell>
            ))} */}
          </Table.Summary.Row>
        </Table.Summary>
      );
    } else return null;
  };
  let filteredColumns = [];
  if (currentPage.filters) {
    filteredColumns = FilterColumns(currentPage.filters, columns, data);
  } else {
    filteredColumns = columns;
  }
  return (
    <>
      <div className={"main_table"}>
        <Table
          size="small"
          pagination={
            notPag
              ? false
              : {
                  pageSizeOptions: ["10", "100", data.length],
                  showLessItems: true,
                  size: "small",
                  showSizeChanger: true,
                }
          }
          bordered
          rowClassName="table_row"
          columns={costumColumns ? costumColumns : filteredColumns}
          dataSource={data}
          loading={loading}
          // scroll={notX ? false : { x: 900 }}
          scroll={{ y: 250, x: 0 }}
          onRow={onClickRow}
          rowSelection={null}
          rowClassName={setRowClassName}
          summary={tableFooter}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    rowId: state.app.rowId,
    values: state.app.values,
    allData: state.app.allData,
    loading: state.app.loading,
    columns: state.app.currentPage.columns,
    currentPage: state.app.currentPage,
    modal: state.app.modal,
    mainTableData: state.app.mainTableData,
  };
};
export default connect(mapStateToProps, {
  setRowId,
  changeDocumentObj,
  setInnerModal,
  setValues,
  setModal,
  stateDetector,
})(SecondTable);
