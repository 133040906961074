import { RiUserStarFill } from "react-icons/ri";
import { GPS_TYPE } from "../Component/Sidebar/PageTypes";

const GPS = {
  title: "Agent GPS",
  path: "/agent-locations",
  icon: <RiUserStarFill></RiUserStarFill>,
  type: GPS_TYPE,
  forms: [],
};
export default GPS;
